<template>
  <div class="card shadow mb-4">
    <div class="card-body pb-1">
      <div class="row">
        <!-- principal de iten do contrato -->
        <div class="col-7 text-left">
          <small class="text-black-50">Financiamento</small>
          <h2 class>{{ contrato.descricao }}</h2>
          <span class="text-black-50">Número do Contrato</span>
          <p class="lead font-weight-bold">{{ contrato.numeroContrato }}</p>
        </div>
        <div class="col-5">
          <div class="chart-pie mb-2">
            <canvas :id="`grafico-financiamento-${ contrato.numeroContrato }`" height="300"></canvas>
          </div>
          <div class="text-center lh-1">
            <small>
              <span>Parcelas pagas:</span>
              <span>{{ contrato.qtdParcelasPagas}}/{{ contrato.qtdParcelas }}</span>
            </small>
          </div>
        </div>
        <div class="col-12 text-center">
          <a
            class="collapsed d-block w-100 fs-2hx lh-1"
            data-toggle="collapse"
            :href="`#details-item-${ contrato.numeroContrato }`"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <i class="fas fa-angle-down"></i>
          </a>
        </div>
      </div>
      <!-- fim principal de iten do contrato -->
      <div class="row collapse hide" :id="`details-item-${ contrato.numeroContrato }`">
        <!-- detalhes de iten do contrato -->
        <div class="col-12 mb-4">
          <hr />
          <h3 class="text-center my-4">Dados do contrato</h3>
          <table class="table table-hover table-left-right dados-contrato">
            <tbody>
              <tr>
                <td>Valor financiado</td>
                <td>{{ contrato.valorFinanciado | formatCurrency }}</td>
              </tr>
              <tr>
                <td>Saldo devedor</td>
                <td> {{ contrato.saldoDevedor | formatCurrency }} </td>
              </tr>
              <tr>
                <td>Início do contrato</td>
                <td> {{ contrato.emissao | formatDate }} </td>
              </tr>
              <tr>
                <td>Situação</td>
                <td>{{ contrato.situacao | formatContratoStatus }}</td>
              </tr>
              <tr>
                <td>Parcelas pagas</td>
                <td>{{ contrato.qtdParcelasPagas}}</td>
              </tr>
              <tr>
                <td>Total de parcelas</td>
                <td>{{ contrato.qtdParcelas }}</td>
              </tr>
              <tr>
                <td>Primeiro vencimento</td>
                <td>{{ contrato.primeiroVencimento | formatDate }}</td>
              </tr>
              <tr>
                <td>Último vencimento</td>
                <td>{{ contrato.ultimoVencimento | formatDate }}</td>
              </tr>
              <tr>
                <td>Próximo vencimento</td>
                <td>{{ contrato.proximoVencimento | formatDate }}</td>
              </tr>
              <tr v-if="!showParcelas">
                <td>Novo vencimento da parcela</td>
                <td>{{ contrato.novaDataParcela | formatDate }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <!-- <button
                type="button"
                class="btn btn-primary d-sm-down-block mx-sm-down-auto mt-2"
                onclick="location.href='parcelas.html#contrato-01';"
              >Quitar contrato</button> -->
            <p class="alert alert-warning p-2" v-if="showErrorMessage">
              {{errorMessage}}
            </p>

            <p class="alert alert-info p-2" v-if="showSuccessMessage">
              <span v-if="showParcelas">
                <span v-if="mensagemEmPlural">
                  Foi concedida a carência para suas parcelas. Suas parcelas com vencimentos nos meses de <strong>{{ mesesCarencia }}</strong> foram prorrogadas para o final do contrato. Seu próximo vencimento é o da parcela do mês de <strong>{{ contrato.proximoVencimentoPosRolagem | formatMonth }}</strong>.
                  <table class="table table-hover text-center fs-08x">
                    <thead>
                      <tr>
                        <th class="py-md-3" scope="col">PARCELA</th>
                        <th class="py-md-3" scope="col">VENCIMENTO</th>
                        <th class="py-md-3" scope="col">NOVO VENCIMENTO</th>
                        <th class="py-md-3" scope="col">VALOR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(parcela, index) in parcelas" v-bind:key="index">
                        <td data-title="Parcela">{{ parcela.parcela }}</td>
                        <td data-title="Vencimento">{{ parcela.vencimento | formatDate }}</td>
                        <td data-title="NovoVencimento">{{ parcela.novoVencimento | formatDate }}</td>
                        <td data-title="Valor">{{ parcela.valor | formatCurrency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span v-else>
                  Foi concedida a carência para sua parcela. Sua parcela com vencimento no mês de <strong>{{ mesesCarencia }}</strong> foi prorrogada para o final do contrato. Seu próximo vencimento é o da parcela do mês de <strong>{{ contrato.proximoVencimentoPosRolagem | formatMonth }}</strong>.
                </span>
              </span>
              <span v-else>
                Foi concedida a carência para sua parcela. Sua parcela com vencimento no mês de <strong>{{this.contrato.proximoVencimento | formatMonth}}</strong> foi prorrogada para o final do contrato. Seu próximo vencimento é o da parcela do mês de <strong>{{this.contrato.proximoVencimentoPosRolagem | formatMonth}}</strong>.
              </span>
            </p>
            <div v-if="!showSuccessMessage">
              <div v-if="showParcelas">
                <p>Ao solicitar a carência, você declara estar ciente de que, se o contrato estiver em condições de sofrer a alteração, as parcelas a seguir passarão a ter um novo vencimento, como apresentado, sendo o próximo vencimento do seu contrato o da parcela <strong>{{contrato.proximaParcelaPosRolagem}}</strong> com vencimento em <strong>{{contrato.proximoVencimentoPosRolagem | formatDate}}</strong>.</p>
                <table class="table table-hover text-center fs-08x">
                  <thead>
                    <tr>
                      <td>Saldo devedor</td>
                      <td> {{ contrato.saldoDevedor | formatCurrency }} </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(parcela, index) in parcelas" v-bind:key="index">
                      <td data-title="Parcela">{{ parcela.parcela }}</td>
                      <td data-title="Vencimento">{{ parcela.vencimento | formatDate }}</td>
                      <td data-title="NovoVencimento">{{ parcela.novoVencimento | formatDate }}</td>
                      <td data-title="Valor">{{ parcela.valor | formatCurrency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <p>Ao solicitar a carência, você declara estar ciente de que, se o contrato estiver em condições de sofrer a alteração, a parcela de número <strong>{{contrato.proximaParcela}}</strong> com vencimento em <strong>{{contrato.proximoVencimento | formatDate}}</strong> passará a ter o vencimento em <strong>{{ contrato.novaDataParcela | formatDate }}</strong>, após a última parcela. Sendo o próximo vencimento do seu contrato o da parcela <strong>{{contrato.proximaParcelaPosRolagem}}</strong> com vencimento em <strong>{{contrato.proximoVencimentoPosRolagem | formatDate}}</strong>.</p>
              </div>
              <p> <input type="checkbox" v-model="aceiteTermosCarencia"> Li e estou de acordo com as condições acima dispostas.</p>
            </div>
            <button
              type="button"
              class="btn btn-primary d-sm-down-block mx-sm-down-auto mb-1 mx-auto mr-sm-3 mt-2"
              v-on:click="solicitarCarencia"
              :disabled="desabilitaSolicitacao"
            >
              <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status" v-if="carregando">
                <span class="sr-only">Loading...</span>
              </div>
              Solicitar Carência
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
// import Contratos from "../api_portal_auto_atendimento/src/model/Contratos";
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";
import Chart from "chart.js";
import * as moment from "moment";

export default {
  name: "ContratoCarenciaItem",
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      processing: false,
      buttonPressed: 0,
      showErrorMessage: false,
      showSuccessMessage: false,
      aceiteTermosCarencia: false,
      parcelas: [],
    };
  },
  computed: {
    carregando: function () {
      return this.processing;
    },
    desabilitaSolicitacao: function() {
      return !(this.aceiteTermosCarencia && !this.showSuccessMessage);
    },
    showParcelas: function () {
      return this.contrato.regraRolagem === 2;
    },
    mensagemEmPlural: function () {
      return this.parcelas.length !== 1;
    },
    mesesCarencia: function () {
      if (this.parcelas.length == 1) {
        return moment(this.parcelas[1].vencimento).format("MMMM");
      } else if (this.parcelas.length > 1) {
        var meses = this.parcelas.slice(0, this.parcelas.length - 1).map((item) => moment(item.vencimento).format("MMMM")).join(", ");
        meses += ` e ${moment(this.parcelas[this.parcelas.length - 1].vencimento).format("MMMM")}`;
        return meses;
      }
      return "teste testando";

      // else if (this.parcelas.length > 1) {
      //   meses = 'teste'; //this.parcelas.slice(0, this.parcelas.length - 2).map(item => moment(item.parcela).format('MMMM')).join(', ');
      //   // meses += ` e ${moment(this.parcelas[this.parcelas.length - 1].parcela).format('MMMM')}`
      //   return meses;
      // } else {
      //   return "";
      // }
    },
  },
  props: {
    contrato: Object,
  },
  methods: {
    solicitarCarencia: function (event) {
      if (event) {
        event.preventDefault();
      }
      if (this.processing) {
        return;
      }

      this.processing = true;
      let config = this.authService.config();
      let contratoApi = new ApiPortalAutoAtendimento.ContratoApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.basePath = config.endpoints.autoatendimento;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken();
      let userData = this.authService.userData();

      contratoApi.diariaRodando(userData.Empresa, (error, data, response) => {
        self.verificaDiaria = data;
        if (data === true) {
          this.errorMessage = "Desculpe-nos, mas o sistema entrou em processo de fechamento diário, bloqueando alguns recursos. Por favor, volte mais tarde e tente novamente.";
          this.exibeErrorMessage = true;
          this.processing = false;
          this.showErrorMessage = true;
        } else {
          var parcelas = [];

          if (this.contrato.regraRolagem === 2) {
            parcelas = this.parcelas.map((item) => {
              return {
                parcela: item.parcela,
                vencimento: item.vencimento,
              };
            });
          } else {
            parcelas = [
              {
                parcela: this.contrato.proximaParcela,
                vencimento: this.contrato.proximoVencimento,
              },
            ];
          }

          contratoApi.executarRolagem(
              userData.Empresa,
              this.contrato.agencia,
              this.contrato.numeroContrato,
              parcelas,
              this.contrato.proximoVencimento,
            (error, data, response) => {
              if (error) {
                this.showErrorMessage = true;
              } else {
                this.showSuccessMessage = true;
                setTimeout(() => {
                  this.$router.go();
                }, 20000);
              }
              this.processing = false;
            }
          );
        }
      });
    },
  },
  mounted() {
    if (this.contrato.regraRolagem === 2) {
      let config = this.authService.config();
      let contratoApi = new ApiPortalAutoAtendimento.ContratoApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.basePath = config.endpoints.autoatendimento;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken();
      let userData = this.authService.userData();

      contratoApi.consultaParcelasCarencia(
        this.contrato.empresa,
        this.contrato.agencia,
        this.contrato.numeroContrato,
        (error, data /*, response*/) => {
          if (error) {
            this.showErrorMessage = true;
          } else {
            this.parcelas = data;
          }
          this.processing = false;
        }
      );
    } else {
      this.parcelas = [
        {
          parcela: this.contrato.proximaParcela,
          vencimento: this.contrato.proximoVencimento,
        },
      ];
    }

    this.$nextTick(() => {
      (Chart.defaults.global.defaultFontFamily = "Nunito"),
        //'-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
        (Chart.defaults.global.defaultFontColor = "#333333");

      // Grafico Financiamento 01
      let ctx = document.getElementById(
        "grafico-financiamento-" + this.contrato.numeroContrato
      );

      new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["Pago", "Em aberto"],
          datasets: [
            {
              data: [
                this.contrato.qtdParcelasPagas,
                this.contrato.qtdParcelas - this.contrato.qtdParcelasPagas,
              ],
              backgroundColor: ["#030065", "#F10707"],
              hoverBackgroundColor: ["#0700de", "#ff4c4c"],
              hoverBorderColor: "rgba(234, 236, 244, 1)",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          tooltips: {
            backgroundColor: "rgb(255,255,255)",
            bodyFontColor: "#333333",
            borderColor: "#dddfeb",
            borderWidth: 1,
            xPadding: 10,
            yPadding: 10,
            displayColors: false,
            caretPadding: 10,
          },
          legend: {
            display: false,
          },
          cutoutPercentage: 80,
        },
      });
    });
  },
};
</script>

<style scoped>
.dados-contrato tbody tr td:first-child {
  text-align: left;
}
</style>