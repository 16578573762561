<template>
  <!-- Main Content -->
  <div id="content" class="mb-5">
    <!-- Begin Page Content -->
    <div id="meus-contratos" class="container-fluid">
      <div class="d-sm-flex align-items-center justify-content-between my-4 pt-2 mx-md-4">
        <h1 class="h3 mb-0">Solicitação de Carência</h1>
      </div>
      <div class="alert alert-warning" role="alert" v-if="exibeErrorMessage">
        {{ errorMessage }}
      </div>
      <div v-if="carregando" class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        Carregando contratos ... 
      </div>
      <div v-if="semContratos" class="text-center">
        <p>
          Você já solicitou prorrogação de carência neste mês ou seu contrato está fora dos critérios para prorrogação.
        </p>
        <p>
          Entre em contato conosco através do nosso e-mail de atendimento cobranca@bancodigimais.com.br para verificar as opções.
        </p>
      </div> 
      <div class="alert alert-warning" role="alert" v-if="showMessageSolicitacaoDisabilitada">
        As solicitações de carência não estão autorizadas no momento.
      </div>
      <div class="row row-list-cards" v-if="!showMessageSolicitacaoDisabilitada">
        <div class="item" v-for="(contrato, index) in contratos" v-bind:key="index" >
          <ContratoItem :contrato="contrato"></ContratoItem>
        </div>
        
      </div>
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- End of Main Content -->
</template>


<script>

import ContratoItem from "../components/ContratoCarenciaItem";
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";

export default {
  name: "solicitacao-carencia",
  components:{
    ContratoItem
  },
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      contratos:[],
      errorMessage:'',
      primeiraConsulta: false,
      podeAcessarSolicitacaoCarencia:true
    }
  },
  computed: {
    exibeErrorMessage: function() {
      return this.errorMessage != '';
    },
    carregando: function() {
      return !this.primeiraConsulta;
    },
    semContratos: function() {
      return this.errorMessage == '' && this.contratos.length == 0 && this.primeiraConsulta;
    },
    showMessageSolicitacaoDisabilitada: function() {
      return !this.podeAcessarSolicitacaoCarencia;
    }
  },
  mounted() {

    var self = this;

    this.authService.verificarAcessoCarencia((result) => {
      self.podeAcessarSolicitacaoCarencia = result;
    });

    this.$nextTick(() => {
      let config = this.authService.config();
      let contratosApi = new ApiPortalAutoAtendimento.ContratoApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.basePath = config.endpoints.autoatendimento;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken();
      let userData = this.authService.userData();
      let cpfcliente = userData.CpfCnpj; 

      if (cpfcliente == ""){
        cpfcliente = localStorage.cpfCnpj;
      }

      contratosApi.consultaContratosPassiveisCarencia(userData.Empresa, cpfcliente, (error, data, response) => {
        this.primeiraConsulta = true;
        if (error) {
          this.errorMessage = 'Erro ao obter contratos.';
        } else {
          this.contratos = data;
          this.errorMessage = '';
        }
      });
    
    });
  }
};
</script>
